<!--
  - Copyright (C) 2020. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <b-container class="py-4" >
    <h1>{{ $t("ems.profile.profile") }}</h1>
    <hr class="pb-3">
    <div v-if="userInfo && userProfile">
      <b-form-group
        label-cols-sm="2"
        :label="$t('ems.profile.userId')+':'"
        label-for="userId"
      >
        <b-form-input id="userId" disabled
                      v-model="userInfo.sub"></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="2"
        :label="$t('ems.profile.username')+':'"
        label-for="username"
      >
        <b-form-input id="username" disabled
                      v-model="userInfo.preferred_username"></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="2"
        :label="$t('ems.profile.email')+':'"
        label-for="email"
      >
        <b-form-input id="email" disabled v-model="userInfo.email"></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="2"
        :label="$t('ems.profile.firstName')+':'"
        label-for="firstname"
      >
        <b-form-input id="firstname" v-model="userProfile.firstName" disabled></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="2"
        :label="$t('ems.profile.lastName')+':'"
        label-for="lastname"
      >
        <b-form-input id="lastname" v-model="userProfile.lastName" disabled></b-form-input>
      </b-form-group>
    </div>
  </b-container>
</template>

<script>
  export default {
    name: 'UserProfile',
    components: {},
    data () {
      return {
        userInfo: null,
        userProfile: null
      }
    },
    methods: {
      loadUserInfo () {
        this.$keycloakmanager.loadUserInfo().then(res => {
          this.userInfo = res
        }).catch(err => {
          console.debug(err)
        })
      },
      loadUserProfile () {
        this.$keycloakmanager.loadUserProfile().then(res => {
          this.userProfile = res
        }).catch(err => {
          console.debug(err)
        })
      }
    },
    mounted () {
      if (this.$keycloakmanager.isConfigured) {
        this.loadUserInfo()
        this.loadUserProfile()
      }
    }
  }
</script>

<style scoped>

</style>
